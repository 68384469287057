<template>
  <div>
    <template v-if="data.type != 'simple'">
      <div class="select-options--tuktuk" @click="openChooseVariantModal()"
        :class="{ 'withItemOnly': data.variablePrices.filter(x => !!x.image).length == 0 }">
        <button class="select-options--tuktuk__button">
          <h2 class="theme--tuktuk__title">{{ $t("tuktukTheme.selectOptions") }}</h2>
          <div class="select-options--tuktuk__button__right-side">
            <span v-for="(attr, i) in data.attributes" v-bind:key="i">
              {{ attr.name
              }}<span v-if="i < data.attributes.length - 1" style="margin-right: 1px">,</span>
            </span>
            <img src="@/assets/icons/TuktukTheme/ic-nav-tuktuk--next--grey.svg" />
          </div>
        </button>

        <!-- Variant product images-->
        <template v-if="data.variablePrices.length>0">
          <template v-if="data.variablePrices.filter(x=>!!x.image).length>0">
            <VueSlickCarousel
              v-bind="carouselSetting"
              class="select-options--tuktuk__thumbnails"
            >
              <template
                v-for="(item, index) in data.variablePrices"     
              >
              <div :key="index" class="select-options--tuktuk__thumbnail"  v-if="!!item.image"  @click="openChooseVariantModal(item)"  >
                <img              
                  :src="getSizedImageUrl(item.image,'thumbnail','product-images')"
                  alt=""
                />
              </div>
              </template>
            </VueSlickCarousel>
          </template>
          <template v-else>
            <VueSlickCarousel
              v-bind="carouselSetting"
              class="select-option-with-image-wrapper withlistItem"
            >
              <template
                v-for="(item, index) in data.variablePrices"     
              >
              <div      
                v-if="item.name"    
                :key="index"
                class="select-options--tuktuk__thumbnail"
                @click="openChooseVariantModal(item)"
              >
                <div class="select-options-with-no-image-itemName">
                  <no-ssr>
                    {{item.name}}
                </no-ssr>
                </div>
                
                </div>
              </template>
            </VueSlickCarousel>  
          </template> 
        </template>     
      </div>
    </template>
    <no-ssr>
      <vs-dialog v-if="!data.embed" overflow-hidden class="theme--tuktuk__modal tuktuk-modal-variant" :class="(!data.multiplyVariations ||
        (data.multiplyVariations)) && data.showProductImages && (data.variantImageIndex > -1 || data.productImages.length > 0)
        ? 'tuktuk-modal-variant--show-thumbnail'
        : ''
        " v-model="chartModal">
        <SelectProduct @updateCartQuantity="updateCartQuantity" :data="{ ...data }" :payload="data.payload"  :itemIdex="selectedVariantIndex"
              @addToCart="addToCart" @showMsg="showMsg" :isVariantModalOpen="isVariantModalOpen" @setStep="setStep" @changeVariant="changeVariant" @increment="increment" @decrement="decrement" />
      </vs-dialog>

      <div v-else class="theme--tuktuk__modal select-options--tuktuk-modal tuktuk-modal-variant"
        :class="{
          'theme--tuktuk__modal--show': chartModalEmbed, 'select-options--tuktuk-modal--show-thumbnail': (!data.multiplyVariations ||
            (data.multiplyVariations)) && data.showProductImages && (data.variantImageIndex > -1 || data.productImages.length > 0)
        }">
        <div class="theme--tuktuk__modal__overflow" @click="chartModalEmbed = false"></div>
        <SelectProduct @updateCartQuantity="updateCartQuantity" :data="{ ...data }" :payload="data.payload" :itemIdex="selectedVariantIndex"
              @addToCart="addToCart" @showMsg="showMsg" :isVariantModalOpen="isVariantModalOpen" @setStep="setStep" @changeVariant="changeVariant" @increment="increment" @decrement="decrement"/>
      </div>
    </no-ssr>
  </div>
</template>
<script>
import config from "./../../../../config.js";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import { getSizedImageUrl } from "@/helpers/imagekitHelper";
import SelectProduct from "./SelectProduct";

export default {
  props: ["data", "payload", "isVariantModalOpen"],
  data() {
    return {
      baseUrl: config.api.replace("/api", ""),
      changeVariant: false,  
      show: false,
      chartModal:false,
      chartModalEmbed:false,
      selectedVariantAddedQty: 1,      
      isAnyVariantwithQtyOne: [],
      selectedVariantIndex: 0,      
      carouselSetting: {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 6,
            },
          },
        ],
      },
      getSizedImageUrl
    };
  },
  components: {  
    VueSlickCarousel,
    SelectProduct
    // Carousel,
  }, 
  methods: {
    chageIndex(variant){       
      if ((this.data.multiplyVariations && !this.data.cartOrder) && variant) {
       // let variablePricesName = Object.keys(val).map(o => val[o].name).join(' / ');       
        this.selectedVariantIndex = this.data.variablePrices.findIndex(item => item.name === variant.name);
      }
    },
    setStep(count) {
      if(this.data.tuktukThemeSetting.TuktukSettingsRedirect){
        this.chartModal= false;
        this.changeVariant= false;       
        this.chartModalEmbed= false;
      setTimeout(()=>{
        this.$emit("setStep", count);
      },500)
      }else{
        this.chartModal= false;
        this.chartModalEmbed= false;      
        this.changeVariant= false;        
      }    
    },
    getSubPrice(attr) {
      let price = 0;
      if (attr.qty) {
        let subPrice = attr.priceCompare ? attr.priceCompare : attr.price;
        if (attr.whosaleData) {
          attr.whosaleData.map((one) => {
            if (one.count && parseInt(one.count) <= parseInt(attr.qty)) {
              subPrice = one.price;
            }
          });
        }
        price = price + subPrice * attr.qty;
      }
      return price;
    },
    checkOption(name, attrval) {
      if (this.data.filterAttributes) {
        return !(this.data.filterAttributes[name + "_" + attrval])
      } else {
        return false;
      }
    },
    getSubPriceCart(attr, cartQty) {
      let price = 0;
      if (cartQty && attr) {
        let subPrice = attr.priceCompare ? attr.priceCompare : attr.price;
        if (attr.whosaleData) {
          attr.whosaleData.map((one) => {
            if (one.count && parseInt(one.count) <= parseInt(cartQty)) {
              subPrice = one.price;
            }
          });
        }

        price = price + subPrice * cartQty;
      }

      return price;
    },
    increment(val, type) {
      if (type == "multipleVariant" && !this.data.cartOrder) {
        if (this.data.variablePrices[val]) {
          this.data.variablePrices[val].qty =
            parseInt(this.data.variablePrices[val].qty) + 1;
        }
        if(this.data.variablePrices[val].qty==1){
          this.addCartItem();
        }
        
      } else if (type == "singleVariant") {
        this.data.cartQuantity = this.data.cartQuantity + 1;
        this.updateCartQuantity();
      }
      this.$forceUpdate();
      if(this.data.cartOrder &&  this.data.multiplyVariations &&  !this.data.tuktukThemeSetting.TuktukSettingsAddTOCart){
          setTimeout(() => {
            this.addToCart('update')
          },500);   
        }
    },
    removeFromCart(index) {
      this.$emit("updateCartSummary", index);
    },
    decrement(val, type) {
      if (type == "multipleVariant" && !this.data.cartOrder) {
        if (
          this.data.variablePrices[val].qty &&
          this.data.variablePrices[val].qty > 0
        ) {
          if (this.data.variablePrices[val].qty < 1) {
            return;
          } else {
            this.data.variablePrices[val].qty =
              this.data.variablePrices[val].qty - 1;
          }
        }
        
      } else if (type == "singleVariant") {
        if (this.data.cartQuantity < 2) {
          if(this.data.cartSummary.length>1){
              if (this.data.cartQuantity < 1) {
                return;
              } else {       
                
                let selectedVariant = this.getSelectedVariantName(this.data.payload.variablePrices)     
                if(this.data.cartSummary.length > 0 && this.data.multiplyVariations && this.data.cartOrder){
                  let priceVarIndex = this.data.cartSummary.findIndex(
                    (e) => e.name == selectedVariant
                  );
                  this.data.cartQuantity=0;
                  this.updateCartQuantity()
                  this.removeFromCart(priceVarIndex);
                }  
                
              }
            }
          return;        
        } else {
          this.data.cartQuantity = this.data.cartQuantity - 1;
        }
        this.updateCartQuantity();
      }
      if(this.data.cartOrder &&  this.data.multiplyVariations &&  !this.data.tuktukThemeSetting.TuktukSettingsAddTOCart){
          setTimeout(() => {
            this.addToCart('update')
          },500);   
        }
      this.$forceUpdate();
    },
    showMsg() {
      this.$emit("showMsg");
    },
    getSelectedVariantName(param) {
      let selectedVariant = Object.values(param);
      selectedVariant = selectedVariant.map((data) => {
        return data.name;
      });
      return selectedVariant
        .toString()
        .replace(/[,]/g, " / ");
    },
    openChooseVariantModal(item) {       
      this.changeVariant = true;      
      this.chartModalEmbed = true;      
      if ((!this.data.multiplyVariations || (this.data.multiplyVariations && this.data.cartOrder)) && item) {
        item.attrs.map((attr) => {
          this.data.payload.variablePrices[
            attr.name
          ].name = attr.value

        })
      }
      let selectedVariant = this.getSelectedVariantName(this.payload.variablePrices)
     
      if(this.data.cartSummary.length > 0 && this.data.multiplyVariations && this.data.cartOrder){
        let priceVar = this.data.cartSummary.find(
          (e) => e.name == selectedVariant
        );
        this.data.cartQuantity=priceVar && priceVar.qty ? priceVar.qty: !this.data.tuktukThemeSetting.TuktukSettingsAddTOCart ? 0 : 1;
        this.$emit("updateCartQuantity", this.data.cartQuantity);
      }   
     
      this.$emit("changeVariant", {status:true,elem:item});
    },
    addToCart() {
      this.$emit("addToCart");
    },
    updateCartQuantity() {
      this.$emit("updateCartQuantity", this.data.cartQuantity);
    },
    addCartItem() {
      this.$emit("addCartItem");
    },
  },
  watch: {    
    "chartModal": {
      handler(val) {        
        this.$emit("changeVariant", val);
      },
    },
    "isVariantModalOpen":{
      handler(val) {
        this.changeVariant = val;
        this.chartModalEmbed = val;      
      }
    },
    "chartModalEmbed": {
      handler(val) {        
        this.$emit("changeVariant", val);
      }
    },    
    "data.changeVariant": {
      handler(val) {        
        this.chartModal=val;
        this.chartModalEmbed=val;
        if(this.data.changeVariantItem && this.data.changeVariantItem.items){         
          Object.keys(this.data.changeVariantItem.items).map(key=>{
            this.data.payload.variablePrices[
            key
              ].name = this.data.changeVariantItem.items[key].name;

          });     
        }    
        if (this.data.changeVariantItem && this.data.changeVariantItem.attrs){
        
          this.selectedVariantIndex = this.data.variablePrices.findIndex(item => item.name === this.data.changeVariantItem.name);               
        }else if(this.data.changeVariantItem && this.data.changeVariantItem.items){
          
          this.selectedVariantIndex = this.data.variablePrices.findIndex(item => item.name === this.data.changeVariantItem.items);          
        }


        
       

       // this.$emit("changeVariant", val);
      }
    },   
    "data.payload.variablePrices": {
      handler(val) {
        // Concatenate values from variablePrices
        let variablePricesName = Object.keys(val).map(o => val[o].name).join(' / ');
        // Find quantity based on the concatenated name
        this.selectedVariantAddedQty = this.data.cartSummary.find(item => item.name === variablePricesName) && this.data.cartSummary.find(item => item.name === variablePricesName).qty || 1;
        if(!this.data.multiplyVariations || (this.data.multiplyVariations && this.data.cartOrder)){
          this.selectedVariantIndex = this.data.variablePrices.findIndex(item => item.name === variablePricesName);
        }       

        
        if(this.data.cartSummary.length > 0 && this.data.multiplyVariations && this.data.cartOrder && !this.data.tuktukThemeSetting.TuktukSettingsAddTOCart ){
            let selectedVariant = this.getSelectedVariantName(this.payload.variablePrices)
          let priceVar = this.data.cartSummary.find(
            (e) => e.name == selectedVariant
          );
          this.data.cartQuantity=priceVar && priceVar.qty ? priceVar.qty: !this.data.tuktukThemeSetting.TuktukSettingsAddTOCart ? 0 : 1;               
        }         
       
      }
    }
  },
  computed:{
    cartTotalPrice: {
      get() {
        return this.data.cartTotal;
      },
      set(val) {
        this.$emit("updateCartTotal", val);
      },
    },
  },
};
</script>

<style lang="scss">
.select-option-with-image-wrapper {
  .slick-list {
    padding: 4px 16px;
  }

  .slick-slide {
    width: fit-content !important;
    margin-right: 10px;
  }
}

.select-options-with-no-image-itemName {
  border-radius: 2px;
  border: 1px solid #EB435B;
  display: flex;
  color: #EB435B;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  height: 32px;
  justify-content: center;
  align-items: center;
  padding: 4px 16px;
  width: fit-content;
}

.selected-variant-qty-counter {
  display: flex;
  height: 24px;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  position: absolute;
  right: -19px;
  top: -12px;
  border-radius: 10px;
  background: #EB435B;
  z-index: 9;

  .qty-counter-value {
    color: #FFF;
    text-align: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    /* 120% */
    letter-spacing: 0.2px;
  }
}

.select-options--tuktuk {
  margin-bottom: 8px;
  background-color: #ffffff;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  padding: 10px 0;
  cursor: pointer;

  .select-options--tuktuk__button {
    background-color: transparent;
    padding: 0;
    border: 0;
    outline: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    width: 100%;
    padding: 0 15px;
    margin-bottom: 8px;

    .select-options--tuktuk__button__right-side {
      display: flex;
      align-items: center;

      span {
        color: #15171c;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
        /* 16px */
        letter-spacing: 0.2px;
        opacity: 0.4;
      }
    }
  }

  .select-options--tuktuk__thumbnails {
    padding-left: 15px;

    .slick-list {
      .slick-slide {
        width: 32px !important;
        height: 32px;
        margin-right: 8px;
        background-color: #f9f7fa;

        &>div {
          height: 100%;
        }
      }

      .select-options--tuktuk__thumbnail {
        height: 100%;
        display: flex;
        align-content: center;

        img {
          border-radius: 4px;
          object-fit: cover;
          width: 100%;
          height: 100%;
          cursor: grab;

          user-drag: none;
          -webkit-user-drag: none;
          user-select: none;
          -moz-user-select: none;
          -webkit-user-select: none;
          -ms-user-select: none;
        }
      }
    }
  }
}

.theme--tuktuk__modal.tuktuk-modal-variant {

  &.vs-dialog-content {
    .vs-dialog {
      .vs-dialog__close {
        top: 15px !important;
        right: 15px !important;
        display: none;

        .vs-icon-close {
          width: 24px;
          height: 24px;

          &:before,
          &:after {
            top: 12px;
            bottom: 12px;
            left: 6px;
            right: 6px;
          }
        }
      }

      .vs-dialog__content {
        .theme--tuktuk__modal__container {
          padding: 0;
          padding-top: 15px;

          .alert-warning1 {
            margin: 0 15px;
          }

          hr {
            margin: 15px 20px 20px;

            &.theme--tuktuk__modal__line-last {
              display: none;
            }

            &.theme--tuktuk__modal__line-w-100 {
              margin-right: 0;
              margin-left: 0;
            }
          }

          .theme--tuktuk__modal__main {
            overflow-y: auto;
            padding: 15px !important;
            // max-height: 400px;

            @media screen and (max-width: 575px) {
              padding: 15px !important;
            }

            .variant-option-qty {
              grid-template-columns: 1fr 80px;
              border-radius: 4px;
              border: 1px solid rgba(21, 23, 28, 0.1);
              cursor: pointer;
              &.variant-option-qty--selected{
                border-color: #EB435B;
                .variant-option-qty__content{
                  .variant-option-qty__content__label{
                    color: #EB435B;
                  }
                }
                
              }

              .variant-option-qty__content {
                &.variant-option-qty__content--show-thumbnail {
                  grid-template-columns: 32px 1fr;
                  align-items: center;

                  .variant-option-qty__content--show-thumbnail__thumbnail {
                    height: 32px;
                    border-radius: 4px;
                    background-color: rgba(21, 23, 28, 0.1);
                    overflow: hidden;

                    img {
                      height: 100%;
                    }
                  }
                }

                p {
                  margin: 0;
                  text-align: left;
                }

                .variant-option-qty__content__label {
                  text-align: left;
                  color: #15171C;
                  font-family: TikTokDisplayRegular;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 160%;
                  letter-spacing: 0.24px;
                }

                .tuktuk-modal-variant__cart-label {
                  display: flex;
                  align-items: center;
                  gap: 4px;
                  color: #eb435b;
                  font-family: TikTokDisplayRegular;
                  font-size: 10px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 12px;
                  /* 120% */
                  letter-spacing: 0.2px;
                }
              }
            }
          }

          .theme--tuktuk__modal__footer {
            display: flex;
            width: 100%;
            padding: 15px;
            gap: 15px;
            background: #ffffff;
            box-shadow: 0px -14px 30px rgba(0, 0, 0, 0.05);

            .pp-button-pink--border,
            .pp-button-pink--fill {
              border-radius: 2px;
              width: 100%;
              flex: 1;
              padding: 10px;
              font-family: TikTokDisplayMedium;
            }
          }
        }
      }
    }
  }

  &.tuktuk-modal-variant--show-thumbnail {
    .vs-dialog {
      .vs-dialog__close {
        top: 19px !important;
      }

      .vs-dialog__content .theme--tuktuk__modal__container {
        padding: 0;

        .theme--tuktuk__modal__main {
          padding: 15px !important;

          @media screen and (max-width: 575px) {
            padding: 15px !important;
          }
        }
      }
    }

    .pp-modal-choose-variant--mobile__thumbnail {
      grid-template-columns: 96px 1fr;
      padding-top: 15px;
    }
  }

  .pp-modal-choose-variant--mobile__thumbnail {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    padding: 15px;
    padding-top: 0;
    border-bottom: 1px solid rgba(161, 162, 164, 0.2);

    .pp-modal-choose-variant--mobile__thumbnail__single {
      width: 96px;
      border-radius: 8px;
      border: 1px solid #bfcee0;
      background-color: #f6f8fc;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .pp-modal-choose-variant--mobile__thumbnail__carousel {
      min-width: 96px;
      min-height: 96px;
      background-color: #f6f8fc;
      border-radius: 8px;
      border: 1px solid #bfcee0;

      .slick-slider {
        width: 96px;
        overflow: hidden;
        border-radius: 8px;
        border: 1px solid #bfcee0;
        background-color: #f6f8fc;

        img {}

        .slick-slide {
          height: 96px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .slick-dots,
        .slick-prev,
        .slick-next {
          display: none !important;
        }
      }
    }

    .pp-modal-choose-variant--mobile__thumbnail__content {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      row-gap: 10px;
      padding-right: 20px;

      p,
      h2 {
        margin: 0;
        text-align: left;
      }

      .pp-modal-choose-variant--mobile__thumbnail__content__number {
        .theme--tuktuk__product-details__price {
          @mixin init-price {
            font-size: 20px;
            font-style: normal;
            font-family: TikTokDisplayBold;
            font-weight: 700;
            line-height: 160%;
            /* 28.8px */
            letter-spacing: 0.36px;
            margin: 0;
          }

          .theme--tuktuk__product-details__price__regular {
            @include init-price;
            color: #15171c;
          }

          .theme--tuktuk__product-details__price__sales {
            @include init-price;
            color: #eb435b;
          }

          .theme--tuktuk__product-details__price__container-sales {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 8px;

            .theme--tuktuk__product-details__price__regular {
              font-size: 14px;
              font-family: TikTokDisplayMedium;
              font-style: normal;
              font-weight: 400;
              line-height: 160%;
              /* 19.2px */
              letter-spacing: 0.24px;
              text-decoration: line-through;
              margin: 0;
              opacity: 0.4;
            }

          }
        }

        overflow-wrap: anywhere;

        .pp-modal-choose-variant--mobile__thumbnail__content__number__price {
          color: #203551;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          /* 133.333% */
          letter-spacing: 0.36px;
        }

        .pp-modal-choose-variant--mobile__thumbnail__content__before-discount {
          color: #fa5757;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.24px;
          text-decoration: line-through;
        }
      }

      .pp-modal-choose-variant--mobile__thumbnail__content__variant {
        color: #6c7e95;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        /* 120% */
        letter-spacing: 0.3px;
        text-transform: capitalize;
      }
    }
  }

  .tuktuk-modal-variant__cart-info {
    padding: 15px 15px 0;

    .tuktuk-modal-variant__cart-info__container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      border-radius: 2px;
      background: #FCE3E6;
      width: 100%;
      min-height: 32px;
      padding: 4px 5px;
    }

    .tuktuk-modal-variant__cart-info__text-number {
      color: #15171C;
      font-family: TikTokDisplayMedium;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 160%;
      /* 22.4px */
      letter-spacing: 0.28px;
      margin: 0;
    }

    .tuktuk-modal-variant__cart-info__text-label {
      color: #15171C;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;
      /* 22.4px */
      letter-spacing: 0.28px;
      margin: 0;

    }

    .tuktuk-modal-variant__cart-info__add {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    .tuktuk-modal-variant__cart-info__total {
      display: flex;
      gap: 8px;

      .tuktuk-modal-variant__cart-info__text-label {
        opacity: 0.4;
      }

      .tuktuk-modal-variant__cart-info__text-bold {
        overflow-wrap: anywhere;
      }
    }
  }

  .add-quantity--tuktuk {
    display: flex;
    justify-content: space-between;
    gap: 5px;
    align-items: center;
    padding: 10px 15px;

    &>label {
      color: #15171c;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;
      /* 19.2px */
      letter-spacing: 0.24px;
      font-family: TikTokDisplayMedium;
    }
  }
}

.withItemOnly {
  .slick-track {
    width: 100% !important;
    display: flex;
    align-items: center;
  }

  .select-options-with-no-image-itemName {
    width: max-content;
  }
}</style>
