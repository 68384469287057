<template>
  <client-only>
    <div class="no-sidebar-narrow-canvas-container" :class="{'mobile-view': relatedData.mobileVersion}">
      <div class="row">
        <div class="col-md-12 mt-2 mb-3">
          <div class="no-sidebar-narrow-canvas-product-content">
            <div class="col-md-12 mt-2 mb-3 p-0" v-if="data.showSeals">
              <div class="d-flex justify-content-center">
                <Seal :data="data" :isSizeWindow="relatedData.isSizeWindow" />
              </div>
            </div>
            <div
              v-for="(producedFieldData, index) in producedFieldDatas"
              :key="index"
              class="min-width-narrow-canvas-container mw-100"
            >
              <div
                class="col-md-12 p-0"
                v-if="data.showProductImages || data.bullets.length"
              >
                <Left
                  :selectedbumpArray="relatedData.selectedbumpArray"
                  :totalBumpOrderPrice="relatedData.totalBumpOrderPrice"
                  :popup="relatedData.popup"
                  :embed="relatedData.embed"
                  :data="data"
                  :form="relatedData.form"
                  :payload="relatedData.payload"
                  :codPrice="relatedData.codPrice"
                  :priceWithoutWhosale="relatedData.priceWithoutWhosale"
                  :loadingPrice="relatedData.loadingPrice"
                  :isBuyButtonLoading="relatedData.isBuyButtonLoading"
                  :delivery="relatedData.delivery"
                  :price="relatedData.price"
                  :weight="relatedData.weight"
                  :quantity="relatedData.quantity"
                  :cartQuantity="relatedData.cartQuantity"
                  :totalPrice="relatedData.totalPrice"
                  :isSizeWindow="relatedData.mobileVersion"
                  :cartSummary="relatedData.cartSummary"
                  :showCaptcha="relatedData.showCaptcha"
                  :pos="producedFieldData"
                  :posName="producedFieldData.name"
                  :productImages="relatedData.productImages"
                  :variantImageIndex="relatedData.variantImageIndex"
                  :posIndex="producedFieldData.posIndex"
                  @onSave="onSave"
                  :cartTotal="relatedData.cartTotal"
                  :shippingDiscountValue="relatedData.shippingDiscountValue"
                  @updateCartTotal="updateCartTotal"
                  @updateCartSummary="updateCartSummary"
                  @cartOrderSummary="cartOrderSummary"
                  :errors="relatedData.errors"
                  @onValidate="onValidate"
                  :submited="relatedData.submited"
                  @onSubmitted="onSubmitted"
                />
              </div>

              <div class="col-md-12 my-3 p-0">
                <Right
                  :selectedbumpArray="relatedData.selectedbumpArray"
                  :popup="relatedData.popup"
                  :embed="relatedData.embed"
                  :data="data"
                  :form="relatedData.form"
                  :payload="relatedData.payload"
                  :codPrice="relatedData.codPrice"
                  :priceWithoutWhosale="relatedData.priceWithoutWhosale"
                  :loadingPrice="relatedData.loadingPrice"
                  :isBuyButtonLoading="relatedData.isBuyButtonLoading"
                  :delivery="relatedData.delivery"
                  :discountLists="relatedData.discountLists"
                  :price="relatedData.price"
                  :weight="relatedData.weight"
                  :noProductOptionQty="relatedData.quantity"
                  :totalPrice="relatedData.totalPrice"
                  :isSizeWindow="relatedData.mobileVersion"
                  :cartSummary="relatedData.cartSummary"
                  :cartQuantity="relatedData.cartQuantity"
                  :cartTotal="relatedData.cartTotal"
                  :showCaptcha="relatedData.showCaptcha"
                  :pos="producedFieldData"
                  :posName="producedFieldData.name"
                  :productImages="relatedData.productImages"
                  :totalQuantity="relatedData.totalQuantity"
                  :variantImageIndex="relatedData.variantImageIndex"
                  :courierOptionDataFinal="relatedData.courierOptionDataFinal"
                  :shippingDiscountValue="relatedData.shippingDiscountValue"
                  :filterAttributes="relatedData.filterAttributes"
                  :isVariableItemSelected="relatedData.isVariableItemSelected"
                  :posIndex="producedFieldData.posIndex"
                  @updateCartTotal="updateCartTotal"
                  @onSave="onSave"
                  @addToCart="addToCart"
                  @cartOrderSummary="cartOrderSummary"
                  @updateCartSummary="updateCartSummary"
                  @showMobile="showMobile"
                  @updateCheckout="updateCheckout"
                  @updateCartQuantity="updateCartQuantity"
                  :errors="relatedData.errors"
                  @onValidate="onValidate"
                  :submited="relatedData.submited"
                  @onSubmitted="onSubmitted"
                  :user="relatedData.user"
                  @applyDiscount="applyDiscount"
                  @clearDiscount="clearDiscount"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </client-only>
</template>

<script>
import Seal from "@/components/Form/seal";
import Left from "@/components/Form/left";
import Right from "@/components/Form/right";

// import { getSizedImageUrl } from "~/helpers/imagekitHelper";

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    relatedData: {
      type: Object,
      required: true,
    },
    producedFieldDatas: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Seal,
    Right,
    Left,
  },
  data() {
    return {
      // You can add data properties here
    };
  },
  methods: {
    updateCartTotal(value) {
      this.$emit("updateCartTotal", value);
    },
    onSave() {
      this.$emit("onSave");
    },
    addToCart() {
      this.$emits("addToCart");
    },
    cartOrderSummary() {
      this.$emit("cartOrderSummary");
    },
    updateCartSummary(index) {
      this.$emit("updateCartSummary", index);
    },
    showMobile(orderSummary) {
      this.$emit("showMobile", orderSummary);
    },
    updateCheckout() {
      this.$emit("updateCheckout");
    },
    updateCartQuantity(value) {
      this.$emit("updateCartQuantity", value);
    },
    onValidate(value) {
      this.$emit("onValidate", value);
    },
    onSubmitted(value) {
      this.$emit("onSubmitted", value);
    },
    applyDiscount(discountTitle) {
      this.$emit("applyDiscount", discountTitle);
    },
    clearDiscount() {
      this.$emit("clearDiscount");
    },
  },
  emits: [
    "updateCartTotal",
    "onSave",
    "addToCart",
    "cartOrderSummary",
    "updateCartSummary",
    "showMobile",
    "updateCheckout",
    "updateCartQuantity",
    "onValidate",
    "onSubmitted",
    "applyDiscount",
    "clearDiscount",
  ],
};
</script>

<style lang="scss">
.no-sidebar-narrow-canvas-container {
  max-width: 350px;

  .checkout-content--left__feature.checkout-content--left__main__product-image {
    display: flex;
    justify-content: center;
    background-color: unset !important;

    .left__main__product-image__container--swiper {
      max-width: 100%;

      .swiper-pagination-bullet {
        margin: 0 5px;
      }
    }
  }

  .checkout-content--left__container {
    .embed-checkout-left__container.checkout-container.d-block.classic {
      .checkout-content--left__main.checkout-content--left__main--container {
        padding: 20px !important;
      }
    }
  }

  &.mobile-view {
    .checkout-content--left__container {
    .embed-checkout-left__container.checkout-container.d-block.classic {
      .checkout-content--left__main.checkout-content--left__main--container {
        padding: 0px !important;
      }
    }
  }
  }
}
</style>

<style lang="scss" scoped>
$spacer: 16px;

.d-flex {
  display: flex !important;
}

.justify-content-center {
  justify-content: center !important;
}

.align-items-center {
  align-items: center !important;
}

.p-0 {
  padding: 0px !important;
}

.p-2 {
  padding: ($spacer * 0.5) !important;
}

.mt-2 {
  margin-top: ($spacer * 0.5) !important;
}

.mb-2 {
  margin-bottom: ($spacer * 0.5) !important;
}

.mb-3 {
  margin-bottom: ($spacer * 1) !important;
}

.my-1 {
  margin-top: ($spacer * 0.25) !important;
  margin-bottom: ($spacer * 0.25) !important;
}

.my-2 {
  margin-top: ($spacer * 0.5) !important;
  margin-bottom: ($spacer * 0.5) !important;
}

.my-3 {
  margin-top: ($spacer * 1) !important;
  margin-bottom: ($spacer * 1) !important;
}

.ml-1 {
  margin-left: ($spacer * 0.25) !important;
}

.mw-100 {
  max-width: 100% !important;
}
.min-width-narrow-canvas-container {
  min-width: 326px !important
}
</style>
