<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import moment from "moment/src/moment";

export default {
  components: {
    VueSlickCarousel,
  },
  props: ['data'],
  data() {
    return {
      moment
    }
  },
  methods: {
    applyDiscount(title){
      this.$emit('applyDiscount',title)
    },
    clearDiscount(){
      this.$emit('clearDiscount')
    },
    checkdisabled(discount) {            
      let discount_amount=0;    
   
      let price =  this.data.price;  
      let totalQuantity=this.data.totalQuantity;

      if(this.data.cartSummary.length > 0){
          totalQuantity=0;
          price = 0;
          this.data.cartSummary.map(async (e) => {      
            totalQuantity = totalQuantity + e.qty;     
            let subPrice = (e.price ? e.price : e.priceOriginal) * e.qty;
              if (e.whosaleData) {
                e.whosaleData.map((one) => {
                  if (one.count && parseInt(one.count) <= parseInt(e.qty)) {
                    subPrice = one.price * parseInt(e.qty);
                  }               
                });
              }             
              price = price + subPrice;     
           
        });
      }      
     if(discount.minimum_requirements.type=='minimum_purchase'){         
        if(Number(discount.minimum_requirements.value)>this.data.totalPrice){
          return discount_amount > 0 
        }        
      }else if(discount.minimum_requirements.type=='minimum_quantity'){       
        if(Number(discount.minimum_requirements.value)>totalQuantity){
          return discount_amount > 0 
        }        
      } 
      if(discount.discount_type=="moneyOffProduct"){


          if(discount.discount_value.type=="fixed"){                   
            discount_amount=(Number(discount.discount_value.value));           
          }else{
            discount_amount =(price *Number(discount.discount_value.value)) / 100;                                        
                           
          }       
          
        }else if(discount.discount_type=="moneyOffOrder"){
          if(discount.discount_value.type=="fixed"){                   
            discount_amount=Number(discount.discount_value.value);         
          }else{
            discount_amount =(this.data.totalPrice *Number(discount.discount_value.value)) / 100;                             
                    
          }  
        }   
    
        return discount_amount > 0        
    },
  },
}
</script>

<template>
  <div class="card-coupon" :class="[(data.highlight == 'coupon' ? 'highlightDiv highlightImage' : '')]">
    <div class="card-coupon__header">
      <h2 class="theme--tuktuk__title">{{$t('coupon.Coupon')}}</h2>
      <img src="@/assets/icons/TuktukTheme/ic-nav-tuktuk--next--grey.svg" />
    </div>
    <VueSlickCarousel class="card-coupon__list" :variableWidth="true" :infinite="false">
      <div :class="{'disabled':!checkdisabled(discount)}"  :disabled="!checkdisabled(discount)" v-bind:key="`discount-${index}`" class="card-coupon__list__item" v-for="(discount,index) in data.discountLists">
        <div>
          <div class="card-coupon__list__item__title">          
            <strong class="m-0 fw-400 color-primary"> {{ discount.title }}</strong>
          </div>
          <!-- <span class="card-coupon__list__item__date">Valid 06/12/2023 - 06/01/2024</span> -->
          <span class="card-coupon__list__item__date">
            {{ discount.end_date ? "Until "+moment(discount.full_end_date).diff(moment(discount.full_start_date), 'days')  > 0 ? moment(discount.full_end_date).diff(moment(discount.full_start_date), 'days')+" days" : 'Until today' : '' }}
          </span>
        </div>
        <button v-if="discount.title==data.payload.discount.code" class="theme--tuktuk__button-pink--fill" @click="clearDiscount()">
          {{ $t('coupon.clear') }}          
        </button>
        <button v-else class="theme--tuktuk__button-pink--fill" @click="checkdisabled(discount) && applyDiscount(discount.title)">
          {{ $t('coupon.claim') }}           
        </button>
      </div>
      <!-- <div class="card-coupon__list__item">
        <div>
          <div class="card-coupon__list__item__title">
            <strong>Diskon Rp10.000</strong>
            <span>on Unta asdasd</span>
          </div>
          <span class="card-coupon__list__item__date">Valid 06/12/2023 - 06/01/2024</span>
        </div>
        <button class="theme--tuktuk__button-pink--fill">
          Klaim
        </button>
      </div> -->
    </VueSlickCarousel>
  </div>
</template>

<style lang="scss" scoped>
.card-coupon {
  padding: 10px 0px;
  margin-bottom: 8px;
  background-color: #FFF;
  overflow: hidden;

  &>:not([hidden])~:not([hidden]) {
    margin-top: 8px !important;
    margin-bottom: 0px !important;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 15px;

    img {
      width: 16px;
      height: 16px;
    }
  }

  &__list {
    display: flex;
    align-items: center;
    gap: 10px;

    &::v-deep {
      .slick-list {
        padding: 0px 15px;
      }
    }

    &__item {
      min-height: 57px;
      width: max-content !important;
      display: flex !important;
      align-items: center;
      gap: 24px;
      padding: 8px 10px;
      background: #EB435B0D;
      border: 1px solid #EB435B26;
      border-radius: 4px;
      margin-right: 10px;
      text-align: left;

      &>div {
        display: flex;
        flex-direction: column;
        width: 19ch;
      }

      &__title {
        color: #EB435B;
        font-size: 12px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        strong {
          font-size: 14px;
          font-weight: 500;
          font-family: 'TikTokDisplayMedium';
        }
      }

      &__date {
        color: #15171C;
        opacity: 0.4;
        font-size: 12px;
      }

      .theme--tuktuk__button-pink--fill {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 58px;
        min-height: 32px;
        height: 32px;
        padding: 4px 16px 4px 16px;
        font-size: 12px;
        border-radius: 2px;
        &:focus,
        &:active {
          outline: none !important;
          border: none !important;
        }
      }
    }
  }
}
</style>