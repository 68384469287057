<template>
  <div class="card-shop-discount" v-if="data.payload.discount || data.payload.automaticDiscount">
    <div class="card-shop-discount__header">
      <img src="@/assets/icons/TuktukTheme/shop-discount.svg" alt="icon" style="width: 20px; height: 20px;">
      <h2 class="theme--tuktuk__title">{{$t('coupon.ShopDiscount')}}</h2>
      <span class="price">Rp{{ Number(data.payload.discount.amount)+Number(data.payload.automaticDiscount.amount) | g_number_format }}</span>
      <img src="@/assets/icons/TuktukTheme/ic-nav-tuktuk--next--grey.svg" />
    </div>
    <div class="card-shop-discount__coupon" v-if="data.payload.discount.code">
      <div>
        <div class="card-shop-discount__coupon__title">
          <strong v-if="data.payload.discount.code">{{data.payload.discount.code}}</strong>       
      </div>    
      </div>
      <div class="card-shop-discount__coupon__applied">
        {{$t('coupon.Applied')}}
      </div>
    </div>
    <div class="card-shop-discount__coupon" v-if="data.payload.automaticDiscount.code">
      <div>
        <div class="card-shop-discount__coupon__title">
          <strong v-if="data.payload.automaticDiscount.code">{{data.payload.automaticDiscount.code}}</strong>       
      </div>    
      </div>
      <div class="card-shop-discount__coupon__applied">
        {{$t('coupon.Applied')}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["data"],
  data() {
    return {
    }
  }
}
</script>

<style lang="scss" scoped>
.card-shop-discount {
  background-color: #FFF;
  margin-bottom: 8px;
  padding: 10px 15px;
  text-align: left;

  &>:not([hidden])~:not([hidden]) {
    margin-top: 8px !important;
    margin-bottom: 0px !important;
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 4px;

    h2 {
      flex: 1 0 auto;
    }

    .price {
      color: #EB435B;
      font-size: 12px;
      font-weight: 500;
      font-family: TikTokDisplayMedium;
    }
  }

  &__coupon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 8px 10px;
    background: #EB435B0D;
    border: 1px solid #EB435B26;
    border-radius: 4px;

    &>div {
      display: flex;
      flex-direction: column;
      min-width: 0;
    }

    &__title {
      color: #EB435B;
      font-size: 12px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      strong {
        font-size: 14px;
        font-weight: 500;
        font-family: 'TikTokDisplayMedium';
      }
    }

    &__date {
      color: #15171C;
      opacity: 0.4;
      font-size: 12px;
    }

    &__applied {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #EB435B1A;
      color: #EB435B;
      font-size: 12px;
      font-weight: 500;
      font-family: 'TikTokDisplayMedium';
      padding: 4px 16px;
      border-radius: 2px;
      height: 32px;
      
    }
  }
}
</style>